import React from "react";
import "./App.css";
import logo from "./assets/logo.jpg";
import dog from "./assets/dog.png";
import cat from "./assets/cat.png";

function App() {
    return (
        <div className="container">
            <img src={logo} alt="CSVET Logo" className="logo" />
            <h1 className="header">
                A sua Clínica Veterinária em <span className="bold">Vila Real de Santo António</span>
            </h1>
            <p className="message">Site em construção</p>
            <div className="contact">
                <p><strong>Seg - Sex:</strong> 10:00 - 13:00 | 14:30 - 19:00</p>
                <p><strong>Sáb:</strong> 10:00 - 13:00</p>
                <p>+351 966 120 612</p>
                <p>catarinasantana.vet@gmail.com</p>
            </div>
            <div className="images">
                <img src={dog} alt="Dog" />
                <img src={cat} alt="Cat" />
            </div>
            <footer className="footer">
                &copy; 2024 CSVET - Centro Veterinário. Todos os direitos reservados.
            </footer>
        </div>
    );
}

export default App;
